import { Button, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";
import color from "../../../common/color";
import { getLpcLoginUrl } from "../../../common/lpc";
import { track } from "../../../utils";
import { useCallback } from "react";

export default function MoreAboutYouFooter({
  disabled,
  onNextButtonClicked,
  onActivity,
}) {
  const handleClick = useCallback(
    (event) => {
      onActivity();
      event.preventDefault();
      track("Sign in link clicked on Sign up form")
        .then(() => {
          window.location.href = getLpcLoginUrl();
        })
        .catch((error) =>
          console.error("An error occurred while tracking event", error)
        );
    },
    [onActivity]
  );

  const handleNextButtonClicked = useCallback(() => {
    onActivity();
    onNextButtonClicked();
  }, [onActivity, onNextButtonClicked]);

  return (
    <div
      className="flex justify-between px-[30px] items-center py-3"
      style={{ boxShadow: "0px -4px 8px -6px rgba(0,0,0,0.3)" }}
    >
      <div
        className="font-normal text-sm cursor-pointer underline"
        style={{ color: color.WiserBlue }}
        onClick={handleClick}
      >
        Already using Live Price Check? Log in
      </div>
      <div className="lpc-track-retailers-button">
        <Tooltip title={disabled ? "Please, complete fields above" : undefined}>
          <Button
            type={disabled ? undefined : "primary"}
            color={disabled ? undefined : "primary"}
            className="rounded-sm h-[42px] flex items-center"
            style={disabled ? undefined : { backgroundColor: color.WiserBlue }}
            disabled={disabled}
            onClick={handleNextButtonClicked}
          >
            <span>Next</span> <RightOutlined />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
