import color from "../../../common/color";
import { Button, Tooltip, notification, Spin } from "antd";
import { useCallback, useState } from "react";
import { createUser } from "../../../common/api";
import { getLpcLoginUrl } from "../../../common/lpc";
import { CheckCircleFilled } from "@ant-design/icons";
import { track } from "../../../utils";
import { updateUserProperties } from "../../../common/full-story";
import alert from "../../../common/alert";

function companyTypeToGroupInformation(companyType) {
  switch (companyType) {
    case "Retailer":
      return "RETAILER";
    case "Brand":
      return "BRAND";
    case "Other":
      return "OTHER";
    default:
      throw new Error(`Unsupported company type [${companyType}]`);
  }
}

function buildSignupData(generalInformationData, moreAboutYouData) {
  return {
    firstname: generalInformationData.firstName,
    lastname: generalInformationData.lastName,
    email: generalInformationData.email,
    password: generalInformationData.password,
    confirmPassword: generalInformationData.confirmedPassword,
    groupInformation: companyTypeToGroupInformation(
      generalInformationData.companyType
    ),
    captchaUnblockingCode: generalInformationData.captchaUnblockingCode,
    authorizedUniversesIds: [moreAboutYouData.categoryId],
    authorizedSitesIds: moreAboutYouData.retailerIds,
    catalogId: moreAboutYouData.subCategoryId,
  };
}

function buildUserMixpanelData(generalInformationData, moreAboutYouData) {
  return {
    firstname: generalInformationData.firstName,
    lastname: generalInformationData.lastName,
    email: generalInformationData.email,
    companyType: generalInformationData.companyType,
    selectedCountry: moreAboutYouData.countryName,
    authorizedUniverse: moreAboutYouData.categoryName,
    authorizedSites: moreAboutYouData.retailersDomains,
    selectedSubCategory: moreAboutYouData.subCategoryName,
    isFreeUser: true,
  };
}

function isValidationErrors(err) {
  return (
    err.code === "ERR_BAD_REQUEST" &&
    err.response.data.validationsErrors?.length > 0
  );
}

function getValidationErrorsAsHtml(err) {
  let validationsErrors = err.response.data.validationsErrors;

  if (validationsErrors.length === 1) {
    return validationsErrors[0].message;
  }

  return (
    <>
      <div className="mb-2">These following fields need to be fixed :</div>
      <ul>
        {validationsErrors.map(({ field, message }) => {
          return (
            <li className="lpc-validation-errors" key={field}>
              {message}
            </li>
          );
        })}
      </ul>
    </>
  );
}

function errorToHumanMessage(err) {
  const error = err.response.data.error;
  if (error === "Captcha not unblocked") {
    return "Please complete captcha";
  }

  return error;
}

function displayErrorAndRedirectToLoginIfEmailExists(
  api,
  err,
  user,
  lpcLoginUrl
) {
  const formattedError = errorToHumanMessage(err);
  const sleepTimeInSeconds = 3;
  let displayedError = formattedError;
  if (formattedError === "Email already exists") {
    displayedError = emailAlreadyExistsMessage();
    alert.info(api, displayedError);
    redirectToLoginPage(user, lpcLoginUrl, sleepTimeInSeconds);
  } else {
    alert.error(api, displayedError);
  }
}

function redirectToLoginPage(user, lpcLoginUrl, sleepTimeInSeconds) {
  const params = new URLSearchParams({
    email: encodeURIComponent(user.email),
    "open-sso": true,
  });
  if (lpcLoginUrl) {
    setTimeout(() => {
      window.location.href = lpcLoginUrl + `?${params.toString()}`;
    }, sleepTimeInSeconds * 1000);
  }
}

function emailAlreadyExistsMessage() {
  return (
    <>
      <div className="flex flex-col">
        <span>Your user account already exists.</span>
        <span>You will be redirected to the login page to sign in</span>
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      </div>
    </>
  );
}

export default function GeneralInformationFooter({
  generalInformationData,
  moreAboutYouData,
  disabled,
  onBeginCreatingAccount,
  onEndCreatingAccount,
  onActivity,
}) {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const createAccount = useCallback(async () => {
    onActivity();

    if (generalInformationData) {
      updateUserProperties(generalInformationData);
    }

    setLoading(true);
    onBeginCreatingAccount();

    const signupData = buildSignupData(
      generalInformationData,
      moreAboutYouData
    );

    const user = buildUserMixpanelData(
      generalInformationData,
      moreAboutYouData
    );

    const lpcLoginUrl = getLpcLoginUrl();
    let success = false;
    try {
      await createUser(signupData);
      track("Sign up form submitted successfully", user, user);
      success = true;
    } catch (err) {
      if (isValidationErrors(err)) {
        alert.error(api, getValidationErrorsAsHtml(err));
        user.error = err.response.data.validationsErrors;
        track("Backend Form validation errors on sign up", user);
      } else if (err.response.data.error) {
        user.error = err.response.data.error;
        track("Backend processing error on sign up", user);
        displayErrorAndRedirectToLoginIfEmailExists(
          api,
          err,
          user,
          lpcLoginUrl
        );
      } else {
        track("Unexpected error on sign up", user);
        alert.error(api, "Unexpected error occurred, please try later");
      }
    } finally {
      onEndCreatingAccount({ success, email: user.email });
      setLoading(false);
    }
  }, [
    generalInformationData,
    moreAboutYouData,
    onBeginCreatingAccount,
    onEndCreatingAccount,
    onActivity,
    api,
  ]);

  const onClickTermsAndConditions = useCallback(
    (event) => {
      event.preventDefault();
      onActivity();
      window.open("https://www.wiser.com/tos/", "_blank");
    },
    [onActivity]
  );

  const onClickPrivacy = useCallback(
    (event) => {
      event.preventDefault();
      onActivity();
      window.open("https://www.wiser.com/privacy/", "_blank");
    },
    [onActivity]
  );

  return (
    <div
      className="flex justify-between px-[30px] items-center py-3"
      style={{ boxShadow: "0px -4px 8px -6px rgba(0,0,0,0.3)" }}
    >
      {contextHolder}
      <div className="font-normal text-sm" style={{ color: color.DarkGrey1 }}>
        <div>By signing up, you agree with Wiser's</div>
        <div>
          <a
            className="underline"
            href={onClickTermsAndConditions}
            style={{ color: color.WiserBlue }}
            target="_blank"
            rel="noreferrer"
          >
            Terms &amp; Conditions
          </a>{" "}
          and{" "}
          <a
            className="underline"
            href={onClickPrivacy}
            style={{ color: color.WiserBlue }}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      <div className="lpc-track-retailers-button">
        <Tooltip title={disabled ? "Please, complete fields above" : undefined}>
          <Button
            loading={loading}
            type={disabled || loading ? undefined : "primary"}
            color={disabled || loading ? undefined : "primary"}
            className="rounded-sm h-[42px]"
            style={
              disabled || loading
                ? undefined
                : { backgroundColor: color.WiserBlue }
            }
            onClick={createAccount}
            disabled={disabled || loading}
          >
            <CheckCircleFilled />
            Start tracking prices
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
