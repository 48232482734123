import React from "react";
import LpcPriceHistory from "../img/lpc-price-history.png";
import PreviewImage from "./PreviewImage";

const PriceHistory = () => {
  return (
    <div className="flex w-full pt-12">
      <div className="w-1/2 flex flex-col items-start justify-center">
        <div className="w-4/5">
          <p className="text-xl font-medium lpc-light-blue pb-3">
            PRICE HISTORY
          </p>
          <p className="text-3xl font-bold pb-8">
            Check Price Changes
            <br />
            Over Time
          </p>
          <p className="text-2xl">
            Observe historical price fluctuations among retailers and analyze
            shorter-term changes in prices by zooming in.
          </p>
        </div>
      </div>
      <div className="w-1/2 flex items-center justify-center px-4">
        <PreviewImage src={LpcPriceHistory} alt="LpcPriceHistory" />
      </div>
    </div>
  );
};

export default PriceHistory;
