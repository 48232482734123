import { Button } from "antd";
import React from "react";
import { handleClick, lpcChromeWebStoreUrl, track } from "../utils";

const Footer = () => {
  const installExtension = async () => {
    track("Install extension", {
      position: "bottom",
    });

    handleClick(lpcChromeWebStoreUrl);
  };

  return (
    <div className="flex flex-col w-full min-w-[1200px] items-center justify-center bg-gray-100 py-[120px]">
      <p className="font-bold text-[38px] leading-[46px] pb-4">
        Price Comparison Made Easy
      </p>
      <p className="text-[24px] leading-[29px] font-medium min-w-[150px] pb-6">
        Streamline price comparisons and save time with Live Price Check.
      </p>
      <Button
        className="min-w-[260px] lpc-button-background"
        type="primary"
        style={{
          borderRadius: 2,
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "24px",
          height: "72px",
        }}
        size="large"
        onClick={installExtension}
      >
        INSTALL EXTENSION
      </Button>
    </div>
  );
};

export default Footer;
