import React from "react";
import LpcSideBarImage from "../img/lpc-side-bar-1.png";
import ZoomedLpcSideBarImage from "../img/zoomed-lpc-side-bar.png";
import { Button } from "antd";
import { lpcChromeWebStoreUrl, handleClick, track } from "../utils";
import PreviewImage from "./PreviewImage";

const GeneralDescriptionPage = () => {
  const installExtension = () => {
    track("Install extension", {
      position: "top",
    });

    handleClick(lpcChromeWebStoreUrl);
  };

  return (
    <>
      <div className="flex w-full mt-16 py-10">
        <div className="w-1/2 flex flex-col items-start justify-center">
          <div className="w-4/5">
            <p
              className="text-xl font-medium leading-normal pb-5"
              style={{ color: "#1890FF" }}
            >
              LIVE PRICE CHECK
            </p>
            <p className="text-4xl font-bold pb-5">
              Check Product Prices At Retailers 75% Faster
            </p>
            <p className="text-2xl pb-5">
              Trusted by <b>500+</b> retailers and brands.
            </p>
            <p className="text-2xl pb-16">
              Compare prices at retailers, and use color highlights to visualize
              product price positioning.
            </p>
            <Button
              className="min-w-[260px] lpc-button-background"
              type="primary"
              style={{
                borderRadius: 2,
                fontSize: "24px",
                lineHeight: "24px",
                height: "60px",
              }}
              size="large"
              onClick={installExtension}
            >
              INSTALL EXTENSION
            </Button>
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-center px-4">
          <PreviewImage
            addShadow={false}
            src={LpcSideBarImage}
            zoomedSrc={ZoomedLpcSideBarImage}
            alt="LpcSideBarImage"
          />
        </div>
      </div>
    </>
  );
};

export default GeneralDescriptionPage;
