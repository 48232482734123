import { QuestionCircleFilled } from "@ant-design/icons";
import color from "../../../../common/color";
import TooltipHelp from "./TooltipHelp";
import { useCallback } from "react";

function buildLabel({ context, label }) {
  if (label) {
    return label;
  }

  switch (context) {
    case "country":
      return "What country do you operate in?";
    case "category":
      return "What product category would you like to track?";
    case "sub-category":
      return "What sub-category would you like to track?";
    default:
      throw new Error(`Not supported context [${context}]`);
  }
}

function buildWidth(context) {
  switch (context) {
    case "country":
      return "200px";
    case "category":
      return "30px";
    case "sub-category":
      return "60px";
    case "retailer":
      return "90px";
    default:
      throw new Error(`Not supported context [${context}]`);
  }
}

export default function Label({
  context,
  label,
  onActivity,
  tooltipHelpToDisplay,
  onCloseTooltipHelp,
  onDisplayTooltipHelp,
}) {
  const onMouseEnter = useCallback(
    () => onDisplayTooltipHelp(context),
    [onDisplayTooltipHelp, context]
  );

  return (
    <div className="flex items-center">
      <div className="w-full" onMouseEnter={onCloseTooltipHelp}>
        {buildLabel({ context, label })}
      </div>
      <div style={{ width: buildWidth(context) }}></div>
      <div className="mr-[9px]">
        <div className="flex items-center cursor-pointer">
          <QuestionCircleFilled
            onMouseEnter={onMouseEnter}
            style={{
              color:
                tooltipHelpToDisplay === context
                  ? color.EnabledQuestionMark
                  : color.DisabledQuestionMark,
            }}
          />
        </div>
        <div>
          <TooltipHelp
            context={context}
            display={tooltipHelpToDisplay === context}
            onActivity={onActivity}
            onClose={onCloseTooltipHelp}
          />
        </div>
      </div>
    </div>
  );
}
