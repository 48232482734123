function getCurrentYear() {
  return new Date().getFullYear();
}

export default function Footer() {
  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-gray-400 font-light px-6 py-1 text-white h-6"
      style={{ fontSize: "10.4px" }}
    >
      ©{getCurrentYear()} Wiser Solutions, Inc. All Rights Reserved.
    </div>
  );
}
