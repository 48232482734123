import { isInitialized, FullStory } from "@fullstory/browser";
import { v4 as uuidv4 } from "uuid";

const debug = process.env.REACT_APP_FULL_STORY_DEBUG === "true";
const fullStoryUserIdKey = "FULL_STORY_USER_ID";

const oldUserPreference = {};
const userPreferenceToTrackLater = {};

const oldUserProperties = {};
const userPropertiesLater = {};

function getUserId() {
  let fullStoryUserId = localStorage.getItem(fullStoryUserIdKey);
  if (!fullStoryUserId) {
    fullStoryUserId = uuidv4();
    localStorage.setItem(fullStoryUserIdKey, fullStoryUserId);
  }

  return fullStoryUserId;
}

function getRetailersCount(retailers) {
  return retailers?.length ? retailers.length : 0;
}

function areSameRetailers(newRetailers) {
  const oldRetailersCount = getRetailersCount(oldUserPreference.retailers);
  if (oldRetailersCount === getRetailersCount(newRetailers)) {
    if (oldRetailersCount === 0) {
      return true;
    }

    for (const newRetailer of newRetailers) {
      if (!oldUserPreference.retailers.includes(newRetailer)) {
        return false;
      }
    }

    return true;
  }

  return false;
}

function isSameUserPreference({
  companyType,
  country,
  category,
  subCategory,
  retailers,
}) {
  return (
    oldUserPreference.companyType === companyType &&
    oldUserPreference.country === country &&
    oldUserPreference.subCategory === subCategory &&
    oldUserPreference.category === category &&
    areSameRetailers(retailers)
  );
}

function isSameUserProperties({ email, firstName, lastName }) {
  return (
    oldUserProperties.email === email &&
    oldUserProperties.firstName === firstName &&
    oldUserProperties.lastName === lastName
  );
}

function updateOldUserPreference({
  companyType,
  country,
  category,
  suCategory,
  retailers,
}) {
  oldUserPreference.companyType = companyType;
  oldUserPreference.country = country;
  oldUserPreference.category = category;
  oldUserPreference.suCategory = suCategory;
  oldUserPreference.retailers = retailers;
}

function updateOldUserProperties({ email, firstName, lastName }) {
  oldUserProperties.email = email;
  oldUserProperties.firstName = firstName;
  oldUserProperties.lastName = lastName;
}

function hasAtLeastOneData({
  companyType,
  country,
  category,
  suCategory,
  retailers,
}) {
  return (
    companyType ||
    country ||
    category ||
    suCategory ||
    hasAtLeastOneRetailer(retailers)
  );
}

function hasAtLeastOneRetailer(retailers) {
  return retailers?.length && retailers[0];
}

function resetUserPreferenceToTrackLater() {
  userPreferenceToTrackLater.companyType = oldUserProperties.companyType;
  userPreferenceToTrackLater.country = oldUserProperties.country;
  userPreferenceToTrackLater.category = oldUserProperties.category;
  userPreferenceToTrackLater.retailers = oldUserProperties.retailers;
}

function resetUserPropertiesLater() {
  userPropertiesLater.email = oldUserProperties.email;
  userPropertiesLater.firstName = oldUserProperties.firstName;
  userPropertiesLater.lastName = oldUserProperties.lastName;
}

export function trackUserPreference({
  companyType,
  country,
  category,
  subCategory,
  retailers,
}) {
  if (
    isInitialized() &&
    hasAtLeastOneData({
      companyType,
      country,
      category,
      subCategory,
      retailers,
    }) &&
    !isSameUserPreference({
      companyType,
      country,
      category,
      subCategory,
      retailers,
    })
  ) {
    const eventName = "User preference";
    const properties = {
      companyType,
      country,
      category,
      retailers,
    };

    if (debug) {
      console.log(`Track event : ${eventName} `, properties);
    }

    FullStory("trackEvent", {
      name: eventName,
      properties,
    });

    updateOldUserPreference({
      companyType,
      country,
      category,
      subCategory,
      retailers,
    });
  }
}

export function trackUserPreferenceLater({
  companyType,
  country,
  category,
  subCategory,
  retailers,
}) {
  userPreferenceToTrackLater.companyType = companyType;
  userPreferenceToTrackLater.country = country;
  userPreferenceToTrackLater.category = category;
  userPreferenceToTrackLater.subCategory = subCategory;
  userPreferenceToTrackLater.retailers = retailers;
}

export function updateUserProperties({ email, firstName, lastName }) {
  if (
    isInitialized() &&
    !isSameUserProperties({ email, firstName, lastName })
  ) {
    const properties = buildUserProperties({ email, firstName, lastName });

    const userId = getUserId();
    if (debug) {
      console.log(`Update user [${userId}] properties : `, properties);
    }

    FullStory("setIdentity", {
      uid: userId,
      properties,
    });

    updateOldUserProperties({ email, firstName, lastName });
  }
}

function buildUserProperties({ email, firstName, lastName }) {
  const properties = {};

  if (email) {
    properties.email = email;
  }

  if (firstName) {
    properties.firstName = firstName;
  }

  if (lastName) {
    properties.lastName = lastName;
  }

  const displayName = buildDisplayName(firstName, lastName);
  if (displayName) {
    properties.displayName = displayName;
  }

  return Object.keys(properties).length ? properties : null;
}

function buildDisplayName(firstName, lastName) {
  if (firstName || lastName) {
    return [firstName, lastName]
      .map((name) => name?.trim())
      .filter(Boolean)
      .join(" ");
  }
}

export function updateUserPropertiesLater({ email, firstName, lastName }) {
  userPropertiesLater.email = email;
  userPropertiesLater.firstName = firstName;
  userPropertiesLater.lastName = lastName;
}

setInterval(() => {
  trackUserPreference(userPreferenceToTrackLater);
  resetUserPreferenceToTrackLater();
  updateUserProperties(userPropertiesLater);
  resetUserPropertiesLater();
}, 2000);
