import { useRef, useEffect, useState } from "react";
import Steps from "./Steps";
import LpcVideo from "../video/lpc.mp4";

function isInViewport(element) {
  const rectangle = element.getBoundingClientRect();
  return (
    rectangle.top >= 0 &&
    rectangle.left >= 0 &&
    rectangle.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rectangle.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

function playFromTo(videoElement, startTime, endTime, onVideoEnded) {
  videoElement.currentTime = startTime;
  videoElement.play();
  setTimeout(() => {
    videoElement.pause();
    videoElement.currentTime = endTime;
    onVideoEnded();
  }, (endTime - startTime) * 1000);
}

const steps = [
  {
    title: "Add extension to Chrome",
    description: "It only takes 2 clicks in a few seconds.",
    video: {
      start: 0,
      end: 4,
    },
  },
  {
    title: "Go to a retailer website",
    description:
      "Visit a retailer website, and go to a page with products in your subscribed categories.",
    video: {
      start: 5,
      end: 17,
    },
  },
  {
    title: "Check colored highlights",
    description:
      "At a glance, identify which items require attention by looking at the highlights!",
    video: {
      start: 17,
      end: 23,
    },
  },
];

const HowLPCWorks = () => {
  const [enableSteps, setEnableSteps] = useState(false);

  const [selectedStepNumber, setSelectedStepNumber] = useState(0);
  const videoRef = useRef(null);
  const stepsContainerRef = useRef(null);
  const [videoToPlay, setVideoToPlay] = useState(steps[0].video);

  useEffect(() => {
    if (videoRef && enableSteps) {
      playFromTo(videoRef.current, videoToPlay.start, videoToPlay.end, () => {
        const nextStepNumber = selectedStepNumber + 1;
        if (nextStepNumber < steps.length) {
          setVideoToPlay(steps[nextStepNumber].video);
          setSelectedStepNumber(nextStepNumber);
        }
      });
    }
  }, [
    videoRef,
    videoToPlay,
    enableSteps,
    selectedStepNumber,
    setVideoToPlay,
    setSelectedStepNumber,
  ]);

  useEffect(() => {
    //Run steps only when all steps is visible in user view port
    if (!enableSteps && stepsContainerRef) {
      const interval = setInterval(() => {
        if (!enableSteps && isInViewport(stepsContainerRef.current)) {
          setEnableSteps(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [enableSteps, stepsContainerRef]);

  function handleOnClickStep(number) {
    if (enableSteps) {
      setVideoToPlay(steps[number].video);
      setSelectedStepNumber(number);
    }
  }

  return (
    <div
      className="w-full py-10 min-w-[1200px]"
      style={{ backgroundColor: "#F7F7F7" }}
    >
      <div className="w-full text-center font-bold text-4xl">
        How Live Price Check Works
      </div>
      <div className="flex w-full items-center pt-20">
        <div className="w-1/2 pl-40 pr-4" ref={stepsContainerRef}>
          <div className="w-4/5">
            <Steps
              steps={steps}
              onClickStep={handleOnClickStep}
              selectedStepNumber={selectedStepNumber}
            />
          </div>
        </div>
        <div className="w-1/2 pr-28 pl-4 flex items-center">
          <video
            className="rounded-lg lpc-shadow"
            ref={videoRef}
            autoPlay={enableSteps}
            muted={true}
          >
            <source src={LpcVideo} />
            Your browser does not support the video. Please update it.
          </video>
        </div>
      </div>
    </div>
  );
};

export default HowLPCWorks;
