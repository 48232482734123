import color from "../common/color";

function buildScrollContainerStyle(position) {
  const style = {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  };

  if (position === "first") {
    style.borderRadius = "8px 8px 0px 0px";
  } else if (position === "last") {
    style.borderRadius = "0px 0px 8px 8px";
  }

  return style;
}

function buildContainerClassNames(isSelected) {
  const classNames = ["flex"];
  if (!isSelected) {
    classNames.push("cursor-pointer");
  }

  return classNames.join(" ");
}

function buildTileStyle(isSelected) {
  const style = {
    color: "#002766",
  };

  if (isSelected) {
    style.backgroundColor = "#E3F2FA";
  }

  return style;
}

const Step = ({
  number,
  title,
  description,
  isSelected,
  position = "middle",
  onClick,
}) => {
  function handleClick() {
    if (!isSelected) {
      onClick(number);
    }
  }

  return (
    <div className={buildContainerClassNames(isSelected)} onClick={handleClick}>
      <div className="mr-4 w-2" style={buildScrollContainerStyle(position)}>
        {isSelected ? (
          <div
            className="w-full h-full rounded-lg"
            style={{ backgroundColor: color.WiserBlue }}
          ></div>
        ) : null}
      </div>
      <div
        className="w-full p-8 rounded-lg flex"
        style={buildTileStyle(isSelected)}
      >
        <div className="font-bold text-3xl mr-3">{number + 1}.</div>
        <div className="w-full">
          <div className="font-bold text-3xl">{title}</div>
          <div className="mt-3 font-medium text-2xl">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Step;
