import { useCallback, useState } from "react";
import color from "../../../common/color";
import { track } from "../../../utils";
import EmbeddedForm from "../EmbeddedForm";

export default function TellUs({ onActivity }) {
  const [openGoogleFormWaitlist, setGoogleFormWaitlist] = useState(false);
  const onTellUs = useCallback(
    (event) => {
      event.preventDefault();
      onActivity();
      setGoogleFormWaitlist(true);
      track("Unsupported Leads link clicked");
    },
    [onActivity]
  );

  const onCloseGoogleFormWaitlist = useCallback(() => {
    onActivity();
    setGoogleFormWaitlist(false);
  }, [onActivity]);

  return (
    <div className="mt-6">
      <div
        style={{ backgroundColor: "#E3F2FA" }}
        className="py-2 px-3 rounded text-sm"
      >
        <span className="font-semibold " style={{ color: "#333333" }}>
          Need something different?{" "}
        </span>
        <a
          href={process.env.REACT_APP_FORM_NEED_SOMETHING_DIFFERENT_URL}
          onClick={onTellUs}
          className="cursor-pointer underline"
          target="_blank"
          rel="noreferrer"
          style={{ color: color.WiserBlue }}
        >
          Click here to let us know
        </a>
      </div>
      <EmbeddedForm
        open={openGoogleFormWaitlist}
        onCancel={onCloseGoogleFormWaitlist}
        url={process.env.REACT_APP_FORM_NEED_SOMETHING_DIFFERENT_URL}
      />
    </div>
  );
}
