import { Select, notification } from "antd";
import { useState, useCallback, useEffect } from "react";
import { fetchCountries } from "../../../common/api";
import Label from "./label/Label";

function countriesToSelectElements(countries) {
  return countries.map(({ code, name }) => {
    return {
      value: code,
      label: name,
    };
  });
}

function filterCountries(input, option) {
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export default function Country({
  disabled,
  trackFullStoryEvent,
  triggerToCloseTooltipHelp,
  onActivity,
  onSearchCountry,
  onSelectCountry,
  tooltipHelpToDisplay,
  onDisplayTooltipHelp,
  onCloseTooltipHelp,
}) {
  const [api, contextHolder] = notification.useNotification();

  const [loadingCountries, setLoadingCountries] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryStatus, setCountryStatus] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [countryClicked, setCountryClicked] = useState(false);

  useEffect(() => {
    setLoadingCountries(true);

    fetchCountries()
      .then((countries) => setCountries(countries))
      .catch((err) => {
        const messageError = "Error occurred on fetching countries";
        console.error(`${messageError} : `, err);
        api.error({
          message: "Error",
          description: messageError,
          placement: "top",
        });
      })
      .finally(() => setLoadingCountries(false));
  }, [api]);

  useEffect(
    () => setCountryStatus(!countryCode && countryClicked ? "error" : null),
    [countryCode, countryClicked]
  );

  useEffect(() => {
    if (countryCode) {
      onSelectCountry({ countryCode, countryName });
    }
  }, [countryCode, countryName, onSelectCountry]);

  const onClick = useCallback(() => {
    onActivity();
    setCountryClicked(true);
  }, [onActivity]);

  const onSearch = useCallback(
    (partialCountryName) => {
      onActivity();
      onSearchCountry(partialCountryName);
    },
    [onActivity, onSearchCountry]
  );

  const onSelect = useCallback(
    (input, option) => {
      onActivity();
      setCountryCode(input);
      setCountryName(option.label);
    },
    [onActivity]
  );

  return (
    <div className="mt-8">
      {contextHolder}
      <Label
        context="country"
        triggerToCloseTooltipHelp={triggerToCloseTooltipHelp}
        onActivity={onActivity}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
      />
      <div className="mt-1 lpc-select">
        <Select
          size="large"
          showSearch
          loading={loadingCountries}
          disabled={disabled || loadingCountries || !countries.length}
          options={countriesToSelectElements(countries)}
          status={countryStatus}
          filterOption={filterCountries}
          onMouseEnter={onCloseTooltipHelp}
          onClick={onClick}
          onSearch={onSearch}
          onSelect={onSelect}
          onBlur={trackFullStoryEvent}
          autoComplete="none"
          className="w-full"
        />
      </div>
    </div>
  );
}
