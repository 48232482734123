import Step from "./Step";

function getPosition(stepsCount, number) {
  if (number === 0) {
    return "first";
  }

  if (number === stepsCount - 1) {
    return "last";
  }

  return "middle";
}

const Steps = ({ steps, selectedStepNumber, onClickStep }) => {
  function handleOnClick(number) {
    onClickStep(number);
  }

  function buildSteps() {
    return steps.map((step, number) => {
      return (
        <Step
          {...step}
          key={number}
          number={number}
          position={getPosition(steps.length, number)}
          isSelected={number === selectedStepNumber}
          onClick={handleOnClick}
        />
      );
    });
  }
  return <>{buildSteps()}</>;
};

export default Steps;
