import clsx from "clsx";

export default function PasswordHelp({ display }) {
  return (
    <div className={clsx("relative", { hidden: !display })}>
      <div
        className="rounded p-2 leading-6 text-sm inline-block absolute z-10 py-1.5 px-3 border border-solid bg-white"
        style={{
          left: "105px",
          top: "-80px",
          boxShadow: "0px 7px 15px -3px rgba(0,0,0,0.1)",
          borderColor: "#e2e2e2",
        }}
      >
        <div className="font-semibold">Password must have at least:</div>
        <ul className="lpc-list">
          <li>8 characters</li>
          <li>1 uppercase character</li>
          <li>1 lower character</li>
          <li>1 digit</li>
          <li>1 special character</li>
        </ul>
      </div>
    </div>
  );
}
