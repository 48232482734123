import Header from "../components/Header";
import GeneralDescriptionPage from "../components/GeneralDescription";
import ColorHighlights from "../components/ColorHighlights";
import HowLPCWorks from "../components/HowLPCWorks";
import PriceHistory from "../components/PriceHistory";
import RetailersPriceList from "../components/RetailersPriceList";
import CustomizedSettings from "../components/CustomizedSettings";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { changePageTitle } from "../common/page";

const containerClassName =
  "flex flex-col items-start justify-center min-w-[1200px] min-h-[650px] px-48 space-y-40";

function Home() {
  useEffect(() => {
    changePageTitle("Live Price Check Download Page");
  }, []);
  return (
    <>
      <Header />
      <div className={containerClassName}>
        <GeneralDescriptionPage />
      </div>
      <HowLPCWorks />
      <div className={containerClassName}>
        <ColorHighlights />
        <RetailersPriceList />
        <PriceHistory />
        <CustomizedSettings />
      </div>
      <Footer />
    </>
  );
}

export default Home;
