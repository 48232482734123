import { useCallback, useState } from "react";
import CompanyType from "./CompanyType";

const companyTypes = ["Retailer", "Brand", "Other"];

export default function AreYouA({ onSelectCompanyType }) {
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);

  const onClickCompanyType = useCallback(
    (selectedCompanyType) => {
      onSelectCompanyType(selectedCompanyType);
      setSelectedCompanyType(selectedCompanyType);
    },
    [onSelectCompanyType]
  );

  function buildCompanyTypes() {
    return companyTypes.map((companyType) => {
      return (
        <CompanyType
          key={companyType}
          onClick={onClickCompanyType}
          selected={companyType === selectedCompanyType}
        >
          {companyType}
        </CompanyType>
      );
    });
  }

  return (
    <div div className="mt-8">
      <div>Are you a...</div>
      <div className="flex mt-1 gap-x-4">{buildCompanyTypes()}</div>
    </div>
  );
}
