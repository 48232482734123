import { Steps } from "antd";

const OnboardingSteps = (props) => {
  return (
    <div className="w-[280px] min-h-[728px] rounded-l-lg onboarding-steps-bar pl-8 pt-8 pr-2">
      <Steps
        current={props.current}
        direction="vertical"
        items={[
          {
            title: "Install extension",
          },
          {
            title: "Configure",
          },
          {
            title: "Start tracking",
          },
        ]}
      ></Steps>
    </div>
  );
};

export default OnboardingSteps;
