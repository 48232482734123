import color from "../../../common/color";
import { useCallback, useState } from "react";
import { selectedRetailersFieldToArray } from "./retailer/RetailersSearch";
import {
  trackUserPreference,
  trackUserPreferenceLater,
} from "../../../common/full-story";
import TellUs from "./TellUs";
import Country from "./Country";
import Category from "./Category";
import SubCategory from "./SubCategory";
import RetailersSearchContainer from "./retailer/RetailersSearchContainer";

function buildMoreAboutYouStyle(visible) {
  const style = { color: color.DarkGrey2 };
  if (!visible) {
    style.display = "none";
  }

  return style;
}

export default function MoreAboutYou({
  visible,
  disabled,
  onData,
  onActivity,
  onDisplayRetailers,
}) {
  const [countryCode, setCountryCode] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [selectedRetailerIds, setSelectedRetailerIds] = useState([]);
  const [selectedRetailersDomains, setSelectedRetailersDomains] = useState([]);
  const [retailersDomainsWithIndex, setRetailersDomainsWithIndex] = useState(
    {}
  );
  const [tooltipHelpToDisplay, setTooltipHelpToDisplay] = useState(null);

  const onSelectCategory = useCallback(
    ({ categoryId, categoryName }) => {
      setCategoryId(categoryId);
      setCategoryName(categoryName);

      onData({
        countryCode,
        countryName,
        categoryId,
        categoryName,
        subCategoryId,
        subCategoryName,
        retailerIds: selectedRetailerIds,
        retailersDomains: selectedRetailersDomains,
      });
    },
    [
      countryCode,
      subCategoryId,
      subCategoryName,
      selectedRetailerIds,
      countryName,
      selectedRetailersDomains,
      onData,
    ]
  );

  const onSelectSubCategory = useCallback(
    ({ subCategoryId, subCategoryName }) => {
      setSubCategoryId(subCategoryId);
      setSubCategoryName(subCategoryName);

      onData({
        countryCode,
        countryName,
        categoryId,
        categoryName,
        subCategoryId,
        subCategoryName,
        retailerIds: selectedRetailerIds,
        retailersDomains: selectedRetailersDomains,
      });
    },
    [
      countryCode,
      categoryId,
      categoryName,
      selectedRetailerIds,
      countryName,
      selectedRetailersDomains,
      onData,
    ]
  );

  const onRetailerIdsSelected = useCallback(
    (retailerIds, retailersDomains, retailersDomainsWithIndex) => {
      onActivity();
      setSelectedRetailerIds(retailerIds);
      setSelectedRetailersDomains(retailersDomains);
      setRetailersDomainsWithIndex(retailersDomainsWithIndex);
      onData({
        countryCode,
        countryName,
        categoryId,
        categoryName,
        subCategoryId,
        subCategoryName,
        retailerIds,
        retailersDomains,
      });
      trackUserPreference({
        country: countryName,
        category: categoryName,
        subCategory: subCategoryName,
        retailers: retailersDomains,
      });
    },
    [
      countryCode,
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      countryName,
      onData,
      onActivity,
    ]
  );

  const onSelectCountry = useCallback(({ countryCode, countryName }) => {
    setCountryCode(countryCode);
    setCountryName(countryName);
  }, []);

  const onSearchRetailer = useCallback(
    (retailer, index) => {
      onActivity();
      const allRetailersDomainsWithIndex = { ...retailersDomainsWithIndex };
      allRetailersDomainsWithIndex[`${index}`] = retailer;

      trackUserPreferenceLater({
        country: countryName,
        category: categoryName,
        subCategory: subCategoryName,
        retailers: selectedRetailersFieldToArray(allRetailersDomainsWithIndex),
      });
    },
    [
      countryName,
      categoryName,
      subCategoryName,
      retailersDomainsWithIndex,
      onActivity,
    ]
  );

  const trackFullStoryEvent = useCallback(
    () =>
      trackUserPreference({
        country: countryName,
        category: categoryName,
        subCategory: subCategoryName,
        retailers: selectedRetailersDomains,
      }),
    [countryName, categoryName, subCategoryName, selectedRetailersDomains]
  );

  const onSearchCountry = useCallback(
    (partialCountryName) => {
      trackUserPreferenceLater({
        country: partialCountryName,
        category: categoryName,
        subCategory: subCategoryName,
        retailers: selectedRetailersDomains,
      });
    },
    [categoryName, subCategoryName, selectedRetailersDomains]
  );

  const onSearchCategory = useCallback(
    (partialCategoryName) => {
      trackUserPreferenceLater({
        country: countryName,
        category: partialCategoryName,
        subCategory: subCategoryName,
        retailers: selectedRetailersDomains,
      });
    },
    [countryName, subCategoryName, selectedRetailersDomains]
  );

  const onSearchSubCategory = useCallback(
    (partialSubCategoryName) => {
      trackUserPreferenceLater({
        country: countryName,
        category: categoryName,
        subCategory: partialSubCategoryName,
        selectedRetailersDomains,
      });
    },
    [countryName, categoryName, selectedRetailersDomains]
  );

  const onDisplayTooltipHelp = useCallback(
    (context) => {
      onActivity();
      setTooltipHelpToDisplay(context);
    },
    [onActivity]
  );

  const onCloseTooltipHelp = useCallback(() => {
    onActivity();
    setTooltipHelpToDisplay(null);
  }, [onActivity]);

  return (
    <div
      className="font-medium text-[16px]"
      style={buildMoreAboutYouStyle(visible)}
    >
      <Country
        disabled={disabled}
        trackFullStoryEvent={trackFullStoryEvent}
        onActivity={onActivity}
        onSearchCountry={onSearchCountry}
        onSelectCountry={onSelectCountry}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
      />
      <Category
        countryCode={countryCode}
        disabled={disabled}
        trackFullStoryEvent={trackFullStoryEvent}
        onActivity={onActivity}
        onSearchCategory={onSearchCategory}
        onSelectCategory={onSelectCategory}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
      />
      <SubCategory
        countryCode={countryCode}
        categoryId={categoryId}
        disabled={disabled}
        trackFullStoryEvent={trackFullStoryEvent}
        onActivity={onActivity}
        onSearchSubCategory={onSearchSubCategory}
        onSelectSubCategory={onSelectSubCategory}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
      />
      <RetailersSearchContainer
        countryCode={countryCode}
        categoryId={categoryId}
        subCategoryId={subCategoryId}
        onActivity={onActivity}
        onRetailerIdsSelected={onRetailerIdsSelected}
        onSearchRetailer={onSearchRetailer}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
        onDisplayRetailers={onDisplayRetailers}
      />
      <TellUs onActivity={onActivity} />
    </div>
  );
}
