import React from "react";
import LpcSettingsImage from "./../img/lpc-settings.png";
import PreviewImage from "./PreviewImage";

const CustomizedSettings = () => {
  return (
    <div className="flex w-full items-center pb-40">
      <div className="w-1/2 pr-4">
        <PreviewImage src={LpcSettingsImage} alt="LPC Settings" />
      </div>
      <div className="flex justify-end w-1/2 pl-4">
        <div className="w-4/5">
          <div className="font-medium text-xl lpc-light-blue mb-3">
            CUSTOMIZED SETTINGS
          </div>
          <div className="text-3xl font-bold mb-8">
            Configure It To Suit Your Needs
          </div>
          <div className="font-normal text-2xl">
            Set currency, language and extension size, as well as define your
            yellow highlights in settings.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizedSettings;
