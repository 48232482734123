import { Select, notification } from "antd";
import { useState, useCallback, useEffect } from "react";
import { fetchUniverses } from "../../../common/api";
import Label from "./label/Label";

function universesToCategoryOptions(universes) {
  return universes.map(({ id, title }) => {
    return {
      value: id,
      label: title,
    };
  });
}

function filterCategories(input, option) {
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export default function Category({
  countryCode,
  disabled,
  trackFullStoryEvent,
  onActivity,
  onSearchCategory,
  onSelectCategory,
  tooltipHelpToDisplay,
  onDisplayTooltipHelp,
  onCloseTooltipHelp,
}) {
  const [api, contextHolder] = notification.useNotification();
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [universes, setUniverses] = useState([]);
  const [categoryState, setCategoryState] = useState();
  const [categoryClicked, setCategoryClicked] = useState(false);
  const [categoryName, setCategoryName] = useState(null);

  useEffect(() => {
    if (!countryCode) {
      return;
    }

    setCategoryId(null);
    setCategoryClicked(false);
    setLoadingCategories(true);

    fetchUniverses(countryCode)
      .then((universes) => setUniverses(universes))
      .catch((err) => {
        const messageError = "Error occurred on fetching categories";
        console.error(`${messageError} : `, err);
        alert.error(api, messageError);
      })
      .finally(() => setLoadingCategories(false));
  }, [countryCode, api]);

  useEffect(
    () => setCategoryState(categoryClicked && !categoryId ? "error" : null),
    [categoryClicked, categoryId]
  );

  useEffect(
    () => onSelectCategory({ categoryId, categoryName }),
    [categoryId, categoryName, onSelectCategory]
  );

  const onClick = useCallback(() => {
    onActivity();
    setCategoryClicked(true);
  }, [onActivity]);

  const onSearch = useCallback(
    (partialCategoryName) => {
      onActivity();
      onSearchCategory(partialCategoryName);
    },
    [onActivity, onSearchCategory]
  );

  const onSelect = useCallback(
    (input, option) => {
      onActivity();
      setCategoryId(input);
      setCategoryName(option.label);
    },
    [onActivity]
  );

  return (
    <div className="mt-8">
      {contextHolder}
      <Label
        context="category"
        onActivity={onActivity}
        tooltipHelpToDisplay={tooltipHelpToDisplay}
        onDisplayTooltipHelp={onDisplayTooltipHelp}
        onCloseTooltipHelp={onCloseTooltipHelp}
      />
      <div className="mt-1 lpc-select">
        <Select
          onMouseEnter={onCloseTooltipHelp}
          size="large"
          showSearch
          loading={loadingCategories}
          value={categoryId}
          disabled={
            disabled || !countryCode || loadingCategories || !universes.length
          }
          options={universesToCategoryOptions(universes)}
          status={categoryState}
          filterOption={filterCategories}
          onClick={onClick}
          onSearch={onSearch}
          onSelect={onSelect}
          onBlur={trackFullStoryEvent}
          autoComplete="none"
          className="w-full"
        />
      </div>
    </div>
  );
}
