import { Spin } from "antd";
import { decode } from "js-base64";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { track } from "../utils";

function parseUser(rawUser) {
  try {
    return JSON.parse(decode(rawUser.replace(/_/g, "/")));
  } catch (err) {
    console.error("Error occurred on parsing user : ", err);
  }
}

function logoutAndRedirectToFormSurvey(user) {
  const url = user.logoutUrl
    ? user.logoutUrl
    : process.env.REACT_APP_FORM_SURVEY_URL;

  window.location.href = url;
}

export default function OnUninstallExtension() {
  const { user } = useParams();

  useEffect(() => {
    const parsedUser = parseUser(user);
    track("Uninstall extension", parsedUser, parsedUser)
      .catch((err) => {
        console.error(
          "Error occurred on tracking uninstall extension event : ",
          err
        );
      })
      .finally(() => logoutAndRedirectToFormSurvey(parsedUser));
  }, [user]);

  return (
    <div className="flex h-screen w-full justify-center items-center">
      <Spin />
    </div>
  );
}
