import React from "react";
import LpcColorHighlights from "../img/lpc-highlights.png";
import PreviewImage from "./PreviewImage";

const ColorHighlights = () => {
  return (
    <div className="flex w-full pt-40">
      <div className="w-1/2 flex flex-col items-start justify-center">
        <div className="w-4/5">
          <p className="text-xl font-medium lpc-light-blue pb-3">
            COLOR HIGHLIGHTS
          </p>
          <p className="text-3xl font-bold pb-8">
            Visualize Price Rank With Colored Highlights
          </p>
          <p className="text-2xl pb-3">
            <span className="text-green-500 font-bold"> Green Highlight: </span>{" "}
            It's the cheapest among retailers.
          </p>
          <p className="text-2xl pb-3">
            <span className="text-yellow-500 font-bold">
              {" "}
              Yellow Highlight:{" "}
            </span>{" "}
            It's not the cheapest, but also not the most expensive.
          </p>
          <p className="text-2xl">
            <span className="text-red-500 font-bold"> Red Highlight: </span>{" "}
            It’s the most expensive among retailers.
          </p>
        </div>
      </div>
      <div className="w-1/2 flex items-center justify-center px-4">
        <PreviewImage src={LpcColorHighlights} alt="Lpc Color Highlights" />
      </div>
    </div>
  );
};

export default ColorHighlights;
