import { useState } from "react";
import { Modal } from "antd";

function PreviewImage({ src, alt, zoomedSrc = null, addShadow = true }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <img
        className={addShadow ? "lpc-shadow" : null}
        src={src}
        alt={alt}
        onClick={() => setVisible(true)}
        style={{ cursor: "pointer" }}
      />

      {
        // Needs to load the zoomed image in background to avoid awaiting image fetching on modal
        zoomedSrc ? <img src={zoomedSrc} alt={alt} className="hidden" /> : null
      }

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
        width="70%"
      >
        <img
          src={zoomedSrc ? zoomedSrc : src}
          alt={alt}
          width="100%"
          className="pt-8"
        />
      </Modal>
    </>
  );
}

export default PreviewImage;
