import clsx from "clsx";
import color from "../../../../common/color";
import EmbeddedForm from "../../EmbeddedForm";
import { useCallback, useState } from "react";
import { track } from "../../../../utils";

function buildWidth(context) {
  switch (context) {
    case "country":
      return "180px";
    case "category":
      return "190px";
    case "sub-category":
      return "165px";
    default:
      return "175px";
  }
}

function buildLeft(context) {
  switch (context) {
    case "country":
      return "-164px";
    case "category":
      return "-174px";
    case "sub-category":
      return "-149px";
    default:
      return "-159px";
  }
}

function buildEventName(context) {
  switch (context) {
    case "country":
      return "Clicked Missing Country Signup Form";
    case "category":
      return "Clicked Missing Category Signup Form";
    case "sub-category":
      return "Clicked Missing Sub-Category Signup Form";
    case "retailer":
      return "Clicked Missing Retailer Signup Form";
    default:
      throw new Error(`Unsupported context [${context}]`);
  }
}

export default function TooltipHelp({ context, display, onActivity, onClose }) {
  const [openForm, setOpenForm] = useState(false);
  const onCloseForm = useCallback(() => setOpenForm(false), []);

  const onOpenUrl = useCallback(
    (event) => {
      event.preventDefault();
      onClose();
      onActivity();
      setOpenForm(true);

      track(buildEventName(context));
    },
    [onActivity, onClose, context]
  );

  const onMouseLeave = useCallback(() => onClose(), [onClose]);

  function buildLink() {
    return (
      <a
        href={process.env.REACT_APP_FORM_NEED_SOMETHING_DIFFERENT_URL}
        onClick={onOpenUrl}
        className="cursor-pointer underline"
        target="_blank"
        rel="noreferrer"
        style={{ color: color.WiserBlue }}
      >
        Let us know
      </a>
    );
  }

  return (
    <div
      className={clsx("relative", { hidden: !display })}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="rounded p-2 leading-6 text-sm inline-block absolute z-10 py-1.5 px-3 border border-solid bg-white"
        style={{
          left: buildLeft(context),
          top: "-85px",
          boxShadow: "0px 7px 15px -3px rgba(0,0,0,0.1)",
          borderColor: "#e2e2e2",
          width: buildWidth(context),
        }}
      >
        Not seeing your {context === "retailer" ? "favorite retailer" : context}
        ? {buildLink()}
      </div>

      <EmbeddedForm
        open={openForm}
        onCancel={onCloseForm}
        url={process.env.REACT_APP_FORM_NEED_SOMETHING_DIFFERENT_URL}
      />
    </div>
  );
}
