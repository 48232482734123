import React from "react";
import LpcSideBarImage from "./../img/lpc-side-bar-2.png";
import PreviewImage from "./PreviewImage";

const RetailersPriceList = () => {
  return (
    <div className="flex w-full items-center">
      <div className="w-1/2 pr-4">
        <PreviewImage src={LpcSideBarImage} alt="LPC side bar" />
      </div>
      <div className="flex justify-end w-1/2 pl-4">
        <div className="w-4/5">
          <div className="font-medium text-xl lpc-light-blue pb-3">
            RETAILERS PRICE LIST
          </div>
          <div className="text-3xl font-bold mb-8">
            View Retailers Price List
          </div>
          <div className="font-normal text-2xl mt-4">
            The list shows all retailers selling the product, their prices,
            price delta, shipping etc.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailersPriceList;
