import { useCallback } from "react";

function buildStyle(selected) {
  const style = {
    borderColor: "#CCCCCC",
  };

  if (selected) {
    style.borderColor = "#B8DDF1";
    style.backgroundColor = "#E3F2FA";
  }

  return style;
}

export default function CompanyType({ children, selected, onClick }) {
  const onClickCompanyType = useCallback(
    () => onClick(children),
    [children, onClick]
  );

  return (
    <div
      className="px-2 py-1 border rounded-sm cursor-pointer"
      style={buildStyle(selected)}
      onClick={onClickCompanyType}
    >
      {children}
    </div>
  );
}
