import { AnalyticsBrowser } from "@segment/analytics-next";

let analytics = null;

export const lpcChromeWebStoreUrl =
  "https://chrome.google.com/webstore/detail/live-price-check/dbgmmfjediknhamdepfnhneeajmdpfjo";

export const track = async (eventName, properties = {}, user) => {
  if (process.env.REACT_APP_SEGMENT_ENABLE !== "true") {
    return false;
  }

  if (!analytics) {
    const segmentKey = process.env.REACT_APP_SEGMENT_KEY;
    if (!segmentKey) {
      throw new Error("Segment key is empty");
    }

    analytics = AnalyticsBrowser.load({
      writeKey: segmentKey,
    });
  }

  if (user) {
    const id = user.username ? user.username : user.email;
    await analytics.identify(id, user);
  }

  await analytics.track(eventName, properties);
};

export const handleClick = (url) => {
  window.open(url, "_blank");
};
