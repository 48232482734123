import { Button, Input, Modal, Tooltip, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import alert from "../../common/alert";
import { sendExitForm } from "../../common/api";
import color from "../../common/color";
import { checkEmail, checkMessageFromExitForm } from "../../common/field";
import { track } from "../../utils";

function splitMessageBy255Characters(message) {
  const splitMessage = [];
  const length = 255;

  for (let i = 0; i < message.length; i += length) {
    splitMessage.push(message.substring(i, i + length));
  }

  return splitMessage;
}

async function pushExitFormDataIntoSegment({ email, message }) {
  try {
    const segmentData = { email };

    const splitMessage = splitMessageBy255Characters(message);
    for (let i = 0; i < splitMessage.length; i++) {
      const key = i === 0 ? "message" : `message_${i + 1}`;
      segmentData[key] = splitMessage[i];
    }

    await track("Exit survey Data Sent", segmentData);
  } catch (err) {
    console.error(err);
  }
}

export default function ExitForm({ open, onMessageSent, onClose }) {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showTooltipMessage, setShowTooltipMessage] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);

  const [email, setEmail] = useState("");
  const [showTooltipEmail, setShowTooltipEmail] = useState(false);
  const [tooltipEmail, setTooltipEmail] = useState(null);
  const [emailStatus, setEmailStatus] = useState(null);

  useEffect(() => {
    setShowTooltipMessage(false);
    setShowTooltipEmail(false);
    setMessage("");
    setEmail("");
    setMessageStatus(null);
    setEmailStatus(null);

    if (open) {
      track("Exit survey Modal Shown");
    }
  }, [open]);

  const onChangeMessage = useCallback(
    (event) => setMessage(event.target.value),
    []
  );

  const onClickMessage = useCallback(() => {
    setShowTooltipMessage(false);
    setTooltipMessage(null);
    setMessageStatus(null);
  }, []);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onClickEmail = useCallback(() => {
    setShowTooltipEmail(false);
    setTooltipEmail(null);
    setEmailStatus(null);
  }, []);

  const onSubmit = useCallback(async () => {
    let check = checkEmail(email, true);
    let allDataOk = true;

    if (check.isOk) {
      setTooltipEmail(null);
      setShowTooltipEmail(false);
      setEmailStatus(null);
    } else {
      allDataOk = false;
      setTooltipEmail(check.error);
      setShowTooltipEmail(true);
      setEmailStatus("error");
    }

    check = checkMessageFromExitForm(message);
    if (check.isOk) {
      setTooltipMessage(null);
      setShowTooltipMessage(false);
      setMessageStatus(null);
    } else {
      allDataOk = false;
      setTooltipMessage(check.error);
      setShowTooltipMessage(true);
      setMessageStatus("error");
    }

    if (!allDataOk) {
      return;
    }

    setLoading(true);
    const exitFormData = { email: email ? email : null, message };

    await pushExitFormDataIntoSegment(exitFormData);

    try {
      const { success } = await sendExitForm(exitFormData);
      if (success) {
        onMessageSent();
      } else {
        alert.error(api, "Unexpected error occurred");
      }
    } catch (err) {
      alert.error(api, `${err}`);
    } finally {
      setLoading(false);
    }
  }, [message, email, api, onMessageSent]);

  const onEmailKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        if (checkEmail(email).isOk && checkMessageFromExitForm(message).isOk) {
          onSubmit();
        }
      }
    },
    [email, message, onSubmit]
  );

  const onCloseClicked = useCallback(async () => {
    await track("Exit survey Modal Closed");
    setShowTooltipMessage(false);
    setShowTooltipEmail(false);
    setTimeout(() => onClose(), 10);
  }, [onClose]);

  return (
    <Modal
      open={open}
      footer={null}
      title="Are you stuck?"
      maskClosable={false}
      onCancel={onCloseClicked}
      entered
      centered
      zIndex={10000}
    >
      {contextHolder}
      <div style={{ fontFamily: "Proxima Nova" }}>
        <div
          className="text-base font-semibold border-b-2 pb-3"
          style={{ color: color.Dark }}
        >
          What stopped you from signing up?
        </div>

        <div className="text-base mt-6" style={{ color: color.Gray9 }}>
          Tell us your thoughts...
        </div>
        <Tooltip title={tooltipMessage} open={showTooltipMessage}>
          <Input.TextArea
            rows={5}
            placeholder="Add comments here"
            className="mt-3"
            status={messageStatus}
            value={message}
            onChange={onChangeMessage}
            onClick={onClickMessage}
          />
        </Tooltip>

        <div className="text-base mt-3" style={{ color: color.Gray9 }}>
          Your email (Optional)
        </div>
        <Tooltip title={tooltipEmail} open={showTooltipEmail}>
          <Input
            className="mt-3"
            status={emailStatus}
            value={email}
            onChange={onChangeEmail}
            onClick={onClickEmail}
            onKeyDown={onEmailKeyDown}
          />
        </Tooltip>
        <Button
          type="primary"
          color="primary"
          className="rounded-sm h-[42px] flex items-center mt-6"
          style={{ backgroundColor: color.WiserBlue }}
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}
