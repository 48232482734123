import { Modal, Spin } from "antd";
import { useCallback, useState } from "react";

export default function EmbeddedForm({ open, onCancel, url }) {
  const [loading, setLoading] = useState(true);

  const onIframeLoaded = useCallback(() => setLoading(false), []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width="800px"
      centered
      footer={null}
      destroyOnClose={true}
    >
      {loading ? (
        <div className="w-full relative">
          <div className="absolute">
            <div
              className="flex justify-center items-center"
              style={{ width: "752px", height: "700px" }}
            >
              <div className="flex">
                <div>Loading, please wait </div>
                <Spin className="ml-3" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <iframe
        title="Tell us what you need"
        src={url}
        width="100%"
        height="700px"
        onLoad={onIframeLoaded}
        style={{ marginTop: "36px" }}
      ></iframe>
    </Modal>
  );
}
