import { Spin, notification } from "antd";
import { useEffect, useState } from "react";
import color from "../../common/color";
import { getLpcLoginUrl } from "../../common/lpc";

function buildFullUrl(email, lpcLoginUrl) {
  const params = new URLSearchParams({
    email: encodeURIComponent(email),
    "open-sso": true,
  });

  return lpcLoginUrl + `?${params.toString()}`;
}

export default function RedirectToSignIn({ email }) {
  const [api, contextHolder] = notification.useNotification();
  const [fullLoginUrl, setFullLoginUrl] = useState(null);

  useEffect(
    () => setFullLoginUrl(buildFullUrl(email, getLpcLoginUrl())),
    [email]
  );

  useEffect(() => {
    if (fullLoginUrl) {
      api.success({
        message: "Information",
        duration: 1,
        description: "Your account is created with success",
        placement: "top",
      });

      setTimeout(() => {
        window.location.href = fullLoginUrl;
      }, 2000);
    }
  }, [fullLoginUrl, api]);

  return (
    <div className="px-[30px] py-8 w-[600px]">
      {contextHolder}
      <div className="mt-36">
        <div className="font-semibold text-2xl text-center">
          Redirecting you to sign in...
        </div>
        <div className="flex w-full justify-center mt-3">
          <a
            className="text-sm"
            href={fullLoginUrl}
            style={{ color: color.WiserBlue }}
          >
            Click here if you are not redirected automatically
          </a>
        </div>
        <div className="flex w-full justify-center mt-16">
          <Spin />
        </div>
      </div>
    </div>
  );
}
