import { useCallback, useEffect, useState } from "react";
import { notification } from "antd";
import OnboardingSteps from "../components/signup/OnboardingSteps";
import Header from "../components/signup/Header";
import Footer from "../components/signup/Footer";
import ExitForm from "../components/signup/ExitForm";
import alert from "../common/alert";
import SignupForm from "../components/signup/SignupForm";
import RedirectToSignIn from "../components/signup/RedirectToSignIn";

const formExitWaitingActivationDelay =
  process.env.REACT_APP_FORM_EXIT_WAITING_ACTIVATION_DELAY;

if (!formExitWaitingActivationDelay) {
  throw new Error("Form exit waiting activation delay not defined");
}

export default function Signup() {
  const [api, contextHolder] = notification.useNotification();

  const [showSignupForm, setShowSignupForm] = useState(true);
  const [email, setEmail] = useState(null);
  const [openExitForm, setOpenExitForm] = useState(false);
  const [referenceExitFormTime, setReferenceExitFormTime] = useState(
    new Date()
  );
  const [activeExitForm, setActiveExitForm] = useState(true);

  useEffect(() => {
    const exitFormInterval = setInterval(() => {
      if (
        activeExitForm &&
        new Date().getTime() - referenceExitFormTime.getTime() >=
          formExitWaitingActivationDelay
      ) {
        setActiveExitForm(false);
        setOpenExitForm(true);
      }
    }, 1000);

    return () => clearInterval(exitFormInterval);
  }, [referenceExitFormTime, activeExitForm]);

  const onMessageSentFromExitForm = useCallback(() => {
    setOpenExitForm(false);
    setActiveExitForm(false);
    alert.success(api, "Your message is sent. Thank you!");
  }, [api]);

  const resetFormExitReferenceTime = useCallback(
    () => setReferenceExitFormTime(new Date()),
    []
  );

  const onCloseExitForm = useCallback(() => {
    setReferenceExitFormTime(new Date());
    setActiveExitForm(true);
    setOpenExitForm(false);
  }, []);

  const onAccountCreated = useCallback((email) => {
    setEmail(email);
    setShowSignupForm(false);
  }, []);

  return (
    <div className="bg-gray-100 min-w-[1000px]">
      {contextHolder}
      <Header />
      <div className="h-screen flex items-center justify-center min-h-[850px] min-w-[1000px]">
        <div className="flex w-[980px]">
          <OnboardingSteps current={1} />
          <div className="flex flex-col justify-between bg-white rounded-r-lg w-full">
            {showSignupForm ? (
              <SignupForm
                resetFormExitReferenceTime={resetFormExitReferenceTime}
                onAccountCreated={onAccountCreated}
              />
            ) : (
              <RedirectToSignIn email={email} />
            )}
          </div>
        </div>
        <Footer />
      </div>
      <ExitForm
        open={openExitForm}
        onMessageSent={onMessageSentFromExitForm}
        onClose={onCloseExitForm}
      />
    </div>
  );
}
