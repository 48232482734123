import { useCallback, useState } from "react";
import color from "../../../../common/color";
import RetailerSearch from "./RetailerSearch";

export function selectedRetailersFieldToArray(selectedRetailers) {
  return Object.keys(selectedRetailers)
    .map((index) => selectedRetailers[index])
    .filter(Boolean);
}

export default function RetailersSearch({
  maxSearchableRetailers,
  retailers,
  disabled,
  onRetailerIdsSelected,
  onSearchRetailer,
  onMouseEnter,
}) {
  const [selectedRetailerIds, setSelectedRetailerIds] = useState({});
  const [selectedRetailersDomains, setSelectedRetailersDomains] = useState({});

  const onAddRetailer = useCallback(
    ({ retailerId, retailerDomain, index }) => {
      const newSelectedRetailerIds = { ...selectedRetailerIds };
      const newSelectedRetailersDomains = { ...selectedRetailersDomains };
      newSelectedRetailerIds[`${index}`] = retailerId;
      newSelectedRetailersDomains[`${index}`] = retailerDomain;
      setSelectedRetailerIds(newSelectedRetailerIds);
      setSelectedRetailersDomains(newSelectedRetailersDomains);
      onRetailerIdsSelected(
        selectedRetailersFieldToArray(newSelectedRetailerIds),
        selectedRetailersFieldToArray(newSelectedRetailersDomains),
        newSelectedRetailersDomains
      );
    },
    [selectedRetailerIds, selectedRetailersDomains, onRetailerIdsSelected]
  );

  const onRemoveRetailer = useCallback(
    (index) => {
      const newSelectedRetailerIds = { ...selectedRetailerIds };
      const newSelectedRetailersDomains = { ...selectedRetailersDomains };
      newSelectedRetailerIds[`${index}`] = null;
      newSelectedRetailersDomains[`${index}`] = null;
      setSelectedRetailerIds(newSelectedRetailerIds);
      setSelectedRetailersDomains(newSelectedRetailersDomains);
      onRetailerIdsSelected(
        selectedRetailersFieldToArray(newSelectedRetailerIds),
        selectedRetailersFieldToArray(newSelectedRetailersDomains),
        newSelectedRetailersDomains
      );
    },
    [selectedRetailerIds, selectedRetailersDomains, onRetailerIdsSelected]
  );

  function buildRetailerElements() {
    const retailersToDisplay = Math.min(
      maxSearchableRetailers,
      retailers ? retailers.length : 0
    );
    const retailerElements = [];

    for (let i = 0; i < retailersToDisplay; i++) {
      retailerElements.push(
        <RetailerSearch
          isLast={i === retailersToDisplay - 1}
          index={i}
          key={i}
          allRetailers={retailers}
          disabled={disabled}
          selectedRetailerIds={selectedRetailersFieldToArray(
            selectedRetailerIds
          )}
          onAddRetailer={onAddRetailer}
          onRemoveRetailer={onRemoveRetailer}
          onSearchRetailer={onSearchRetailer}
        />
      );
    }

    return retailerElements;
  }

  return (
    <div
      className="border rounded px-2"
      style={{ borderColor: color.Gray5 }}
      onMouseEnter={onMouseEnter}
    >
      {buildRetailerElements()}
    </div>
  );
}
