import { useEffect, useState } from "react";
import { Spin, notification } from "antd";
import { fetchRetailers } from "../../../../common/api";
import RetailersSearch from "./RetailersSearch";
import Label from "../label/Label";

const defaultMaxSearchableRetailers = 3;

function getMaxSearchableRetailers(retailers) {
  return retailers
    ? Math.min(defaultMaxSearchableRetailers, retailers.length)
    : 0;
}

export default function RetailersSearchContainer({
  tooltipHelpToDisplay,
  disabled,
  countryCode,
  categoryId,
  subCategoryId,
  onRetailerIdsSelected,
  onSearchRetailer,
  onActivity,
  onDisplayTooltipHelp,
  onCloseTooltipHelp,
  onDisplayRetailers,
  triggerToCloseTooltipHelp,
}) {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [retailers, setRetailers] = useState([]);
  const [diplayRetailers, setDisplayRetailers] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (countryCode && categoryId && subCategoryId) {
      fetchRetailers({ countryCode, categoryId, subCategoryId })
        .then((retailers) => setRetailers(retailers))
        .catch((err) => {
          const messageError = "Error occurred on fetching retailers";
          console.error(`${messageError} : `, err);
          alert.error(api, messageError);
        })
        .finally(() => setLoading(false));
    }
  }, [countryCode, categoryId, subCategoryId, api]);

  useEffect(() => {
    const display =
      countryCode && categoryId && subCategoryId && retailers.length;
    setDisplayRetailers(display);
    onDisplayRetailers(display);
  }, [countryCode, categoryId, subCategoryId, retailers, onDisplayRetailers]);

  function renderLoading() {
    if ((countryCode && categoryId, subCategoryId)) {
      return (
        <div className="flex justify-center mt-4">
          <Spin />
        </div>
      );
    }

    return null;
  }

  function renderRetailers() {
    if (!diplayRetailers) {
      return null;
    }

    const maxSearchableRetailers = getMaxSearchableRetailers(retailers);

    return (
      <div className="mt-8">
        <Label
          tooltipHelpToDisplay={tooltipHelpToDisplay}
          context="retailer"
          triggerToCloseTooltipHelp={triggerToCloseTooltipHelp}
          label={
            maxSearchableRetailers === 2
              ? "Select 2 retailer websites to track"
              : "Select at least 2 retailer websites to track"
          }
          onActivity={onActivity}
          onDisplayTooltipHelp={onDisplayTooltipHelp}
          onCloseTooltipHelp={onCloseTooltipHelp}
        />
        <div className="mt-1">
          <RetailersSearch
            maxSearchableRetailers={maxSearchableRetailers}
            retailers={retailers}
            disabled={disabled}
            onRetailerIdsSelected={onRetailerIdsSelected}
            onSearchRetailer={onSearchRetailer}
            onMouseEnter={onCloseTooltipHelp}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {contextHolder}
      {loading ? renderLoading() : renderRetailers()}
    </div>
  );
}
