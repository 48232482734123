class Alert {
  info(api, message, description) {
    api.info({
      message: message,
      description: description,
      placement: "top",
    });
  }

  success(api, description) {
    api.success({
      message: "Success",
      description,
      placement: "top",
    });
  }

  error(api, description) {
    api.error({
      message: "Error",
      description,
      placement: "top",
    });
  }
}

const alert = new Alert();
export default alert;
