import React from "react";
import WiserLogo from "../img/wiser-logo.png";
import { Button } from "antd";
import { lpcChromeWebStoreUrl, handleClick, track } from "../utils";

const Header = () => {
  const addToChrome = async () => {
    track("Add to chrome");
    handleClick(lpcChromeWebStoreUrl);
  };

  return (
    <div className="flex justify-between bg-white border-b-2 border-gray-100 z-[1000] fixed w-full py-1 px-4">
      <img
        src={WiserLogo}
        className="flex items-center justify-center w-26 h-16"
        alt="wiser-logo"
      />
      <div className="flex items-center justify-center">
        <Button
          className="lpc-button-background"
          style={{ borderRadius: 2 }}
          type="primary"
          size="large"
          onClick={addToChrome}
        >
          ADD TO CHROME
        </Button>
      </div>
    </div>
  );
};

export default Header;
