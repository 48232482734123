import { Input, Tooltip } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useCallback, useState } from "react";

const gray = "rgba(0, 0, 0, 0.45)";

export default function PasswordField({
  tootltip,
  tootltipOpened,
  status,
  value,
  addOkIcon,
  onChange,
  onClick,
  onBlur,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const hidePassword = useCallback(() => setShowPassword(false), []);
  const displayPassword = useCallback(() => setShowPassword(true), []);

  function buildSuffixes() {
    const suffixes = [];

    if (showPassword) {
      suffixes.push(
        <EyeInvisibleOutlined
          key="hide-password"
          className="cursor-pointer"
          style={{ color: gray }}
          onClick={hidePassword}
        />
      );
    } else {
      suffixes.push(
        <EyeOutlined
          key="show-password"
          className="cursor-pointer"
          style={{ color: gray }}
          onClick={displayPassword}
        />
      );
    }

    if (addOkIcon && !status && value) {
      suffixes.push(
        <CheckCircleFilled key="password-ok" className="text-green-500" />
      );
    }

    return suffixes;
  }

  return (
    <Tooltip placement="topRight" title={tootltip} open={tootltipOpened}>
      <Input
        className="rounded-sm text-[16px]"
        size="large"
        status={status}
        value={value}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        type={showPassword ? "text" : "password"}
        suffix={buildSuffixes()}
        autoComplete="none"
      />
    </Tooltip>
  );
}
