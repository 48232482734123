import "./App.css";
import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { init, isInitialized } from "@fullstory/browser";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import OnUninstallExtension from "./pages/OnUninstallExtension";
import { updateUserProperties } from "./common/full-story";

if (!isInitialized()) {
  console.log("Initialize Full Story ...");
  init(
    {
      orgId: process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID,
    },
    () => {
      console.log("Full Story initialized");
      updateUserProperties({ email: "", firstName: "", lastName: "" });
    }
  );
}

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup/:lpcLoginUrl" element={<Signup />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/on-uninstall-extension/:user"
          element={<OnUninstallExtension />}
        />
        <Route
          path="/on-uninstall-extension"
          element={<OnUninstallExtension />}
        />
      </Routes>
    </HashRouter>
  );
}
