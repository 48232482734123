import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URI;

export async function fetchCountries() {
  const response = await axios.get("/plg/countries/new");
  return response.data;
}

export async function fetchUniverses(countryCode) {
  const url = `/plg/universes?country-code=${countryCode}`;
  const response = await axios.get(url);
  return response.data;
}

export async function fetchCatalogs({ countryCode, categoryId }) {
  const url = `/plg/catalogs?country-code=${countryCode}&universe-id=${categoryId}`;
  const response = await axios.get(url);
  return response.data;
}

export async function fetchRetailers({
  countryCode,
  categoryId,
  subCategoryId,
}) {
  const url = `/plg/retailers?country-code=${countryCode}&universe-id=${categoryId}&catalog-id=${subCategoryId}`;
  const response = await axios.get(url);
  return response.data;
}

export async function createUser(signupData) {
  const response = await axios.post("/plg/create-user", signupData);
  return response.data;
}

export async function sendExitForm(exitForm) {
  const response = await axios.post("/plg/send-exit-form", exitForm);
  return response.data;
}
