const color = {
  WiserLightBlue: "#F8FCFF",
  WiserBlue: "#0071BC",
  WiserBlue6: "#B8DDF1",
  WiserBlue7: "#E3F2FA",
  DarkGray5: "#AEAEAE",
  DarkGrey1: "#333333",
  DarkGrey7: "#EBEBEB",
  DarkGrey2: "#525252",
  DarkGray6: "#CCCCCC",
  DarkGray4: "#8F8F8F",
  Gray1: "#FFFFFF",
  Gray5: "#D9D9D9",
  Gray9: "#434343",
  Dark: "#333333",
  EnabledQuestionMark: "#9AB3C4",
  DisabledQuestionMark: "#C0CFDA",
};

export default color;
