const extensionProtocol = "chrome-extension://";
const lpcLoginUrlKey = "lpc-login-url";

function isValidLpcLoginUrl(lpcLoginUrl) {
  return (
    lpcLoginUrl?.startsWith(extensionProtocol) &&
    lpcLoginUrl.length > extensionProtocol.length
  );
}

export function storeLpcLoginUrl(lpcLoginUrl) {
  if (!isValidLpcLoginUrl(lpcLoginUrl)) {
    return false;
  }

  localStorage.setItem(lpcLoginUrlKey, lpcLoginUrl);
  return true;
}

export function getLpcLoginUrl() {
  let lpcLoginUrl = localStorage.getItem(lpcLoginUrlKey);
  if (!lpcLoginUrl) {
    lpcLoginUrl = process.env.REACT_APP_LPC_LOGIN_URL;
  }

  return lpcLoginUrl;
}
