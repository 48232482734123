import { Tooltip } from "antd";
import { useCallback } from "react";
import color from "../../common/color";

const TabState = {
  DISABLED: 0,
  UNSELECTED: 1,
  SELECTED: 2,
};

export { TabState };

function buildStyle(tabState, areFieldsValid) {
  const style = {};

  switch (tabState) {
    case TabState.DISABLED:
      style.color = color.DarkGray5;
      style.borderColor = color.DarkGray5;
      style.cursor = "not-allowed";
      break;
    case TabState.SELECTED:
      style.color = color.DarkGrey1;
      style.borderColor = color.WiserBlue;
      break;
    case TabState.UNSELECTED:
      style.color = areFieldsValid ? color.DarkGrey1 : color.DarkGray5;
      style.borderColor = color.WiserBlue;
      style.cursor = "pointer";
      break;
    default:
      break;
  }

  return style;
}

export default function Tab({
  tabState,
  areFieldsValid,
  tooltip,
  onSelect,
  onActivity,
  children,
}) {
  const onClick = useCallback(() => {
    if (tabState === TabState.UNSELECTED) {
      onActivity();
      onSelect();
    }
  }, [tabState, onSelect, onActivity]);

  return (
    <Tooltip title={tooltip}>
      <div
        className="w-full font-semibold text-base border-solid border-t-4 pt-2"
        style={buildStyle(tabState, areFieldsValid)}
        onClick={onClick}
      >
        {children}
      </div>
    </Tooltip>
  );
}
