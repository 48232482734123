import clsx from "clsx";
import color from "../../../../common/color";
import { Tooltip, AutoComplete } from "antd";
import {
  LinkOutlined,
  CloseCircleOutlined,
  CloudOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

function getOptions(allRetailers, selectedRetailerIds) {
  return allRetailers
    .filter(({ id }) => !selectedRetailerIds.includes(id))
    .map(({ domain, iconUrl }) => {
      return {
        value: domain,
        label: (
          <div className="flex items-center min-w-[550px] text-[16px] my-1">
            <div className="mr-2 w-[15px]">
              {iconUrl ? <img src={iconUrl} alt="icon" /> : <CloudOutlined />}
            </div>
            <div>{domain}</div>
          </div>
        ),
      };
    });
}

function getRetailerId(allRetailers, retailerDomain) {
  for (const retailer of allRetailers) {
    if (retailer.domain === retailerDomain) {
      return retailer.id;
    }
  }

  return null;
}

export default function RetailerSearch({
  disabled,
  isLast,
  index,
  allRetailers,
  selectedRetailerIds,
  onAddRetailer,
  onRemoveRetailer,
  onSearchRetailer,
}) {
  const [retailerDomain, setRetailerDomain] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(getOptions(allRetailers, selectedRetailerIds));
  }, [allRetailers, selectedRetailerIds]);

  useEffect(() => {
    setRetailerDomain(null);
  }, [allRetailers]);

  const onChangeRetailer = useCallback(
    (retailerDomain) => {
      setRetailerDomain(retailerDomain);

      const retailerId = getRetailerId(allRetailers, retailerDomain);
      if (Number.isInteger(retailerId)) {
        onAddRetailer({ retailerId, retailerDomain, index });
      }
    },
    [allRetailers, index, onAddRetailer]
  );

  const filterRetailers = useCallback((input, option) => {
    return option.value.toLowerCase().includes(input.toLowerCase());
  }, []);

  const onBlurRetailer = useCallback(() => {
    const retailerId = getRetailerId(allRetailers, retailerDomain);
    if (!Number.isInteger(retailerId)) {
      setRetailerDomain(null);
      onRemoveRetailer(index);
    }
  }, [allRetailers, retailerDomain, index, onRemoveRetailer]);

  const openRetailer = useCallback(() => {
    if (retailerDomain) {
      window.open(`https://${retailerDomain}`);
    }
  }, [retailerDomain]);

  const removeRetailer = useCallback(() => {
    setRetailerDomain(null);
    onRemoveRetailer(index);
  }, [index, onRemoveRetailer]);

  const onSearch = useCallback(
    (retailer) => {
      onSearchRetailer(retailer, index);
    },
    [onSearchRetailer, index]
  );

  return (
    <div
      className={clsx(
        "flex justify-between items-center py-1 font-normal text-[16px] lpc-search",
        {
          "border-b": !isLast,
        }
      )}
      style={isLast ? null : { borderColor: color.Gray5 }}
    >
      <div>Retailer {index + 1}</div>
      <div className="flex items-center">
        <div
          className="mr-1 lpc-icon cursor-pointer"
          style={{ color: color.Gray5 }}
        >
          <Tooltip title="Open retailer">
            <LinkOutlined onClick={openRetailer} />
          </Tooltip>
        </div>
        <div>
          {/** We can't use retailer id approach in AutoComplete because of this issue https://github.com/ant-design/ant-design/issues/21882 **/}
          <AutoComplete
            size="large"
            disabled={disabled}
            value={retailerDomain}
            options={options}
            placeholder="Search ..."
            bordered={false}
            onChange={onChangeRetailer}
            onBlur={onBlurRetailer}
            onSearch={onSearch}
            filterOption={filterRetailers}
            style={{
              width: "250px",
            }}
          />
        </div>
      </div>
      <div className="lpc-icon cursor-pointer" style={{ color: color.Gray5 }}>
        <Tooltip title="Remove retailer">
          <CloseCircleOutlined onClick={removeRetailer} />
        </Tooltip>
      </div>
    </div>
  );
}
