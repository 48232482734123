import logo from "../../img/signup/logo/wiser-logo-white.png";

export default function Header() {
  return (
    <header
      className="fixed top-0 flex py-2 pl-5 w-full z-10"
      style={{ backgroundColor: "rgb(14, 63, 101)" }}
    >
      <img src={logo} alt="logo" className="w-6 mr-2" />
      <span className="text-white font-bold">Live Price Check</span>
    </header>
  );
}
