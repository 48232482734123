import { Select, notification } from "antd";
import { useState, useCallback, useEffect } from "react";
import { fetchCatalogs } from "../../../common/api";
import alert from "../../../common/alert";
import Label from "./label/Label";

function catalogsToOptions(catalogs) {
  return catalogs.map(({ id, title }) => {
    return {
      value: id,
      label: title,
    };
  });
}

function filterSubCategories(input, option) {
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export default function SubCategory({
  countryCode,
  categoryId,
  disabled,
  trackFullStoryEvent,
  onActivity,
  onSearchSubCategory,
  onSelectSubCategory,
  tooltipHelpToDisplay,
  onDisplayTooltipHelp,
  onCloseTooltipHelp,
}) {
  const [api, contextHolder] = notification.useNotification();
  const [loadingCategories, setLoadingSubCategories] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [catalogs, setCatalogs] = useState([]);
  const [subCategoryState, setSubCategoryState] = useState();
  const [subCategoryClicked, setSubCategoryClicked] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState(null);

  useEffect(() => {
    if (!countryCode || !categoryId) {
      return;
    }

    setSubCategoryId(null);
    setSubCategoryClicked(false);
    setLoadingSubCategories(true);

    fetchCatalogs({ countryCode, categoryId })
      .then((catalogs) => setCatalogs(catalogs))
      .catch((err) => {
        const messageError = "Error occurred on fetching categories";
        console.error(`${messageError} : `, err);
        alert.error(api, messageError);
      })
      .finally(() => setLoadingSubCategories(false));
  }, [countryCode, categoryId, api]);

  useEffect(
    () =>
      setSubCategoryState(
        subCategoryClicked && !subCategoryId ? "error" : null
      ),
    [subCategoryClicked, subCategoryId]
  );

  useEffect(
    () =>
      onSelectSubCategory({
        subCategoryId,
        subCategoryName,
      }),
    [subCategoryId, subCategoryName, onSelectSubCategory]
  );

  const onClick = useCallback(() => {
    onActivity();
    setSubCategoryClicked(true);
  }, [onActivity]);

  const onSearch = useCallback(
    (partialSubCategoryName) => {
      onActivity();
      onSearchSubCategory(partialSubCategoryName);
    },
    [onActivity, onSearchSubCategory]
  );

  const onSelect = useCallback(
    (input, option) => {
      onActivity();
      setSubCategoryId(input);
      setSubCategoryName(option.label);
    },
    [onActivity]
  );

  function render() {
    return (
      <div className="mt-8">
        {contextHolder}
        <Label
          context="sub-category"
          onActivity={onActivity}
          tooltipHelpToDisplay={tooltipHelpToDisplay}
          onDisplayTooltipHelp={onDisplayTooltipHelp}
          onCloseTooltipHelp={onCloseTooltipHelp}
        />
        <div className="mt-1 text-[#AEAEAE] text-[13px]">
          You can browse other sub-categories on the retailer website
        </div>
        <div className="mt-1 lpc-select">
          <Select
            onMouseEnter={onCloseTooltipHelp}
            size="large"
            showSearch
            loading={loadingCategories}
            value={subCategoryId}
            disabled={
              disabled ||
              !countryCode ||
              !categoryId ||
              loadingCategories ||
              !catalogs.length
            }
            options={catalogsToOptions(catalogs)}
            status={subCategoryState}
            filterOption={filterSubCategories}
            onClick={onClick}
            onSearch={onSearch}
            onSelect={onSelect}
            onBlur={trackFullStoryEvent}
            autoComplete="none"
            className="w-full"
          />
        </div>
      </div>
    );
  }

  return <>{countryCode && categoryId ? render() : null}</>;
}
