import { passwordStrength } from "check-password-strength";

const inactiveBackgroundColor = "bg-gray-500";
const passwordStrengths = ["Too weak", "Weak", "Medium", "Strong"];

const backgroundColors = {
  "Too weak": "bg-red-500",
  Weak: "bg-yellow-500",
  Medium: "bg-orange-500",
  Strong: "bg-green-500",
};

function getLevelBar(strength) {
  for (let i = 0; i < passwordStrengths.length; i++) {
    if (passwordStrengths[i] === strength) {
      return i;
    }
  }
}

function getBackgroundColor(password, index) {
  const strength = passwordStrength(password).value;
  const levelBar = getLevelBar(strength);

  return index <= levelBar
    ? backgroundColors[strength]
    : inactiveBackgroundColor;
}

function buildBarElements(password) {
  const barElements = [];
  for (let i = 0; i < passwordStrengths.length; i++) {
    const backgroundColor = getBackgroundColor(password, i);
    const classNames = ["w-full", "h-full", "rounded-full", backgroundColor];
    if (i < passwordStrengths.length - 1) {
      classNames.push("mr-1");
    }
    barElements.push(<div key={i} className={classNames.join(" ")}></div>);
  }

  return barElements;
}

export default function PasswordStrengthChecker({ password }) {
  return (
    <div className="flex justify-between h-1">{buildBarElements(password)}</div>
  );
}
